import Download from "./downLoad";

function App() {
  return (
    <div >
      <Download/>
    </div>
  );
}

export default App;

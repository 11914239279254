import {Toast} from "antd-mobile";

export function formatDate(time,type){
	if (!time) {
		return ''
	}
	const date = new Date(time)
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const seconds = String(date.getSeconds()).padStart(2, '0');
	if (type === 'YYYY-MM-DD'){
		return `${year}-${month}-${day}`
	}
	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}


/*时间戳转换*/
export function formatTime(formatTime) {
	const date = new Date(formatTime);
	const Y = date.getFullYear() + '-';
	const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
	const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '  ';
	const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
	const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
	const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
	return Y + M + D + h + m + s;
}

export function formatYMDTime(formatTime) {
	const date = new Date(formatTime);
	const Y = date.getFullYear() + '/';
	const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
	const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '  ';
	
	return Y + M + D ;
}


export function formatMDTime(formatTime) {
	const nowTime = new Date().getTime()
	const date = new Date(formatTime);
	const time = date.getTime()
	const Y = date.getFullYear() + '-';
	const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
	const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '  ';
	const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
	const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
	const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
	if (nowTime-time > 365*1000*60*60*24){
		return Y + M + D + h + m + s;
	}else  {
		return M + D
	}
	
}

export function getPickerDate()  {
	function getYearArray() {
		const currentDate = new Date();
		const currentYear = currentDate.getFullYear();
		const startYear = 1950;
		
		const yearArray = Array.from({ length: currentYear - startYear + 1 }, (_, index) => {
			return String(startYear + index);
		});
		
		return yearArray;
	}
	const range = (start, end) => {
		const result = [];
		for (let i = start; i <= end; i++) {
			result.push(String(i).padStart(2, '0'))
		}
		return result;
	};
	const yearArray = getYearArray();
	const monthArray = range(1,12)
	const dayArray = range(1,30)
	return [yearArray,monthArray,dayArray]
	
}

/*时间格式转换*/
export function format(formatTime) {
	const a = new Date(formatTime).getTime();
	const date = new Date(a);
	const Y = date.getFullYear() + '-';
	const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
	const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '  ';
	const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
	const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
	const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
	return Y + M + D + h + m + s;
}

//格式时间差
export function formatTimeText(timestamp) {
	const now = Date.now();
	const diff = now - timestamp;
	
	if (diff >= 365 * 24 * 60 * 60 * 1000) {
		const date = new Date(timestamp);
		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		const day = date.getDate();
		return `${year}年${month}月${day}日`;
	} else if (diff >= 3 * 24 * 60 * 60 * 1000) {
		const date = new Date(timestamp);
		const month = date.getMonth() + 1;
		const day = date.getDate();
		return `${month}月${day}日`;
	} else if (diff >= 2 * 24 * 60 * 60 * 1000) {
		const days = Math.floor(diff / (24 * 60 * 60 * 1000));
		return `${days}天前`;
	} else if (diff >= 24 * 60 * 60 * 1000) {
		return '昨天';
	} else if (diff >= 60 * 60 * 1000) {
		const hours = Math.floor(diff / (60 * 60 * 1000));
		return `${hours}小时前`;
	} else if (diff >= 60 * 1000) {
		const minutes = Math.floor(diff / (60 * 1000));
		return `${minutes}分钟前`;
	} else {
		return '刚刚';
	}
}

/*时间格式转换月日*/
export function formatMonthDay(formatTime) {
	const a = new Date(formatTime).getTime();
	const date = new Date(a);
	const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
	const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '  ';
	const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
	const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
	return M + D + h + m ;
}

export function insertStr (str, index, insertStr) {
	const ary = str.split('');		// 转化为数组
	ary.splice(index, 0, insertStr);	// 使用数组方法插入字符串
	return ary.join('');				// 拼接成字符串后输出
}

export const generateRandomNumber = () => {
	const min = 10000000; // 最小值（包含）
	const max = 99999999; // 最大值（包含）
	
	// 使用 Math.random() 生成 0 到 1 之间的随机小数，
	// 然后乘以 (max - min + 1) 得到一个 0 到 (max - min) 之间的随机数，
	// 再加上 min，将随机数范围调整为 min 到 max。
	return Math.floor(Math.random() * (max - min + 1)) + min;
};


export function isValidPhoneNumber(phoneNumber) {
	const regex = /^1[3456789]\d{9}$/;
	return regex.test(phoneNumber);
}

export function areObjectsEqual(obj1, obj2) {
	if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
		return false; // 如果不是对象则直接返回false
	}
	
	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);
	
	if (keys1.length !== keys2.length) {
		return false; // 属性数量不相等，返回false
	}
	
	for (let key of keys1) {
		if (!obj2.hasOwnProperty(key)) {
			return false; // obj2缺少obj1的某个属性，返回false
		}
		
		const value1 = obj1[key];
		const value2 = obj2[key];
		
		if (typeof value1 === 'object' && typeof value2 === 'object') {
			if (!areObjectsEqual(value1, value2)) {
				return false; // 递归比较子对象的属性值
			}
		} else if (value1 !== value2) {
			return false; // 属性值不相等，返回false
		}
	}
	
	return true; // 所有属性及其值均相等，返回true
}


export function arePropertiesEqual(obj1, obj2, excludedProperties) {
	const keys1 = Object.keys(obj1).filter(key => !excludedProperties.includes(key));
	const keys2 = Object.keys(obj2).filter(key => !excludedProperties.includes(key));
	
	if (keys1.length !== keys2.length) {
		return false; // 忽略指定属性后，属性数量不相等，返回false
	}
	
	for (let key of keys1) {
		const value1 = obj1[key];
		const value2 = obj2[key];
		
		if (typeof value1 === 'object' && typeof value2 === 'object') {
			if (!arePropertiesEqual(value1, value2, excludedProperties)) {
				return false; // 递归比较子对象的属性值
			}
		} else if (value1 !== value2) {
			return false; // 属性值不相等，返回false
		}
	}
	
	return true; // 所有除开指定属性的其他属性及其值均相等，返回true
}

export function copyText(text) {
	try {
		const input = document.createElement('textarea');
		input.value = text;
		document.body.appendChild(input);
		input.select();
		document.execCommand('copy');
		document.body.removeChild(input);
		Toast.show({icon:'success',content:'复制成功'},)
	} catch (err){
		Toast.show({icon:'fail',content:'复制失败'},)
	}
	
}

export function getStrUrl(str) {
	const regex = /<img[^>]+src="([^">]+)"(?![^<]*<\/video>)/g;
	const matches = str.match(regex);
	
	if (matches) {
		return matches.map(match => match.match(/src="([^">]+)"/)[1]);
	}
	return [];
}

export function getVideoSrc(str) {
	const regex = /<video[^>]+src="([^">]+)"(?![^<]*<\/video>)/g;
	const matches = str.match(regex);
	
	if (matches) {
		return matches.map((match) => match.match(/src="([^">]+)"/)[1]);
	}
	
	return [];
}


export const getListTotalNum = ({data}) => {
	console.log(data)
	const num = data.reduce((accumulator, currentValue) => {
		return accumulator + currentValue.num;
	}, 0);
	return num
}

export const isWeixin = () => {
	let ua = navigator.userAgent.toLowerCase()
	return ua.indexOf('micromessenger') !== -1 && ua.indexOf('wxwork') === -1
	// ua.indexOf('wxwork') === -1 这句是排除企业微信用的
}
